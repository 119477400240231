<template>
    <div id="bottom-bar">
        <div class="contact">
            <div class="contact1">联系我们</div>
            <div class="contact2">QQ:2199170632</div>
            <div class="contact2">邮箱:hbsruiying@163.com</div>
        </div>
        <div class="num">
            <!-- <div class="contact2">鄂ICP备2023031487号</div> -->
            <a href="https://beian.miit.gov.cn" class="contact3"> 鄂ICP备2023031487号 </a>
            <div class="contact2">Copyright © 2023 - 2025 hbsry.com 版权所有</div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        
    }
}

</script>
<style>
#bottom-bar {
    width: 108rem;
    height: 5.6rem;
    background: url('../assets/img/bottom-bar.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    padding: 10rem;
}

.contact {
    margin-right: 20rem;
}

.contact1 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 1rem;
}

.contact2 {
    font-size: 1.3rem;
    color: #FFFFFF;
    margin-bottom: 1rem;
}
.contact3{
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: #4d1a8b;
}
</style>