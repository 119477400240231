<template>
    <div id="about">
        <div class="title">关于我们</div>
        <div class="title1">ABOUT US</div>
        <div class="about1">{{ about }}</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            about: '湖北省瑞盈网络科技有限公司是一家专业的网络科技公司，我们的团队拥有丰富的经验和专业知识。我们的服务范围包括网站开发和设计、移动应用开发、电子商务解决方案、云计算和大数据分析等。我们致力于为客户提供创新、可靠的解决方案，帮助他们是实现业务增产和时长竞争力。',
        }
    },
}
</script>
<style>
#about{
    margin: 3rem 0;
}
.title{
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
}
.title1{
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 3rem;
    color: #a8a8a8; 
}
.about1{
    width: 60rem;
    margin: 0 auto;
    line-height: 1.6;
    font-size: 1.5rem;
    text-align: center;
}
</style>