<template>
    <div id="top-bar">
        <div class="toptitle" v-if="title.name">
            <img :src="title.logo" alt="">
            <div>{{ title.name }}</div>
        </div>
        <img v-else src="../assets/img/logo.png" alt="" srcset="">
        <div class="top-bar">
            <div :class="topIndex == i ? 'top-bar1 top-bar2' : 'top-bar1'" v-for="(item, i) in list" :key="i"
                @click="topChange(i)">{{ item }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: []
        },
        title: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            topIndex: 0,
        }
    },
    onLoad() {

    },
    methods: {
        topChange(i) {
            this.topIndex = i
            this.$emit('toLocation', i)
        }
    }
}
</script>
<style>
#top-bar {
    width: 118rem;
    height: 90px;
    padding: 0 5rem;
    background-color: #FFFFFF;
    position: fixed;
    top: 0;
}

#top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#top-bar img {
    width: 24.2rem;
    height: 2.4rem;
}

.toptitle {
    width: 26rem;
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    font-weight: 700;
}

#top-bar .toptitle img {
    width: 5rem;
    height: 5rem;
    margin-right: 2rem;
}

.top-bar {
    width: 70rem;
    display: flex;
    justify-content: end;
}

.top-bar1 {
    font-size: 1.5rem;
    margin-right: 2rem;
    font-weight: 500;
    cursor: pointer;
}

.top-bar1:hover {
    color: #1673F6;
}

.top-bar2 {
    padding-bottom: 1rem;
    border-bottom: 0.2rem solid #1673F6;
}
</style>