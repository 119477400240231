import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home.vue'
import Assistant from '../views/Ai-zs/assistant.vue'
import Draw from '../views/Ai-draw/draw.vue'
import Draws from '../views/Ai-drawS/draw.vue'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/assistant',
    name: 'assistant',
    component: Assistant,
  },{
    path: '/draw',
    name: 'draw',
    component: Draw,
  },{
    path: '/draws',
    name: 'draws',
    component: Draws,
  }
];

const router = new VueRouter({
  routes
});

export default router;
