import Vue from 'vue'
import App from './App.vue'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入axios
import axios from 'axios'
// 引入router
import router from './router/router'
Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.prototype.axios = axios;
// 引入rem
import './utils/rem'
new Vue({
  el: '#app',
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
